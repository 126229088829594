import Cookies from "js-cookie";

const COOKIES_KEY = "cookie-agreement";

$(function () {
  const cookiesElement = document.querySelector(".cookies");
  const cookiesButton = document.getElementById("cookies-accept");
  const HIDE_CLASS = "cookies--hide";

  const hasAccepeted = Cookies.get(COOKIES_KEY);

  if (!hasAccepeted) {
    //Element starts hidden to prevent from being shown when the resources are slow loading
    cookiesElement.classList.remove(HIDE_CLASS);

    cookiesButton.addEventListener(
      "click",
      () => {
        Cookies.set(COOKIES_KEY, true, { sameSite: "strict", expires: 365 });
        cookiesElement.classList.add(HIDE_CLASS);
      },
      { once: true }
    );
  }
});
